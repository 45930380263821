import React from 'react';
import { Link } from 'gatsby';

export default class EventTimeline extends React.Component {
  render() {
    const { events = [], className, noTags } = this.props;

    const buildYearTag = (name, key) => (
      <header className="timeline-header" key={key}>
        <span className="tag is-medium is-uppercase has-text-weight-bold">
          {name}
        </span>
      </header>
    );
    const buildMonthTag = (name, key) => (
      <header className="timeline-header" key={key}>
        <span className="tag is-uppercase has-text-weight-bold">{name}</span>
      </header>
    );
    const buildEvent = (event) => (
      <div
        className={
          event.frontmatter.primary ? 'timeline-item' : 'timeline-item'
        }
        key={event.id}
      >
        <div
          className={
            event.frontmatter.primary
              ? 'timeline-marker is-icon is-white'
              : 'timeline-marker'
          }
        />
        <div
          className={
            event.frontmatter.cancelled
              ? 'timeline-content is-strikethrough'
              : 'timeline-content'
          }
        >
          <p className="heading">
            <span className="has-text-white-bis">
              {event.frontmatter.start_date}
            </span>{' '}
            <span>{event.frontmatter.start_time}</span>
            <span> &mdash; </span>
            {event.frontmatter.over24h ? (
              <span className="has-text-white-bis">
                {event.frontmatter.end_date}{' '}
              </span>
            ) : null}
            <span>{event.frontmatter.end_time}</span>
          </p>
          <p>
            <Link
              className={
                'has-text-weight-bold is-uppercase is-size-' +
                (event.frontmatter.primary ? '5' : '6')
              }
              to={'/' + event.fields.slug}
            >
              {event.frontmatter.title}
            </Link>
          </p>
          {event.frontmatter.price ? (
            <p className="has-text-weight-light is-size-7">{event.frontmatter.price}</p>
          ) : null}
        </div>
      </div>
    );

    const entries = [];
    let curYear = null,
      curMonth = null;
    for (const { node: event } of events || []) {
      if (!noTags) {
        if (curYear !== event.frontmatter.start_year) {
          curYear = event.frontmatter.start_year;
          entries.push(buildYearTag(curYear, `${event.id}-year`));
          entries.push(
            <div className="timeline-item" key={`${event.id}-spacer`} />
          );
        }
        if (curMonth !== event.frontmatter.start_month) {
          curMonth = event.frontmatter.start_month;
          entries.push(buildMonthTag(curMonth, `${event.id}-month`));
        }
      }
      entries.push(buildEvent(event));
    }
    if (!noTags) {
      entries.push(buildYearTag(+curYear + 1, 'end'));
    }

    return <div className={className}>{entries}</div>;
  }
}
