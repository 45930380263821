import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import EventTimeline from '../components/EventTimeline';
import NewsList from '../components/NewsList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const IndexPageTemplate = ({
  title,
  content,
  contentComponent,
  events = [],
  news = [],
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <section className="section">
        <PageContent className="content" content={content} />
      </section>
      <section className="section">
        <div className="columns">
          <div className="column is-half">
            <h2 className="title is-size-4 is-uppercase">
              <Link to="/uutiset/" className="has-text-white-ter">
                Uutiset
              </Link>
            </h2>
            <NewsList news={news} className="" />
            <Link to="/uutiset/" className="button is-background is-flush-left">
              <FontAwesomeIcon icon={faEllipsisV} size="lg" />
            </Link>
          </div>
          <div className="column is-half">
            <h2 className="title is-size-4 is-uppercase has-text-right has-text-white-bis">
              <Link to="/tapahtumat/" className="has-text-white-ter">
                Tapahtumat
              </Link>
            </h2>
            <EventTimeline
              events={events}
              className="timeline is-rtl no-headers"
              noTags
            />
            <div className="has-text-right is-timeline-align">
              <Link to="/tapahtumat/" className="button is-background">
                <FontAwesomeIcon icon={faEllipsisV} size="lg" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const IndexPage = ({ data }) => {
  const { html, frontmatter } = data.codaPage;

  return (
    <Layout>
      <IndexPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        content={html}
        events={data.events && data.events.edges}
        news={data.news && data.news.edges}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    codaPage: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    codaPage(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    events: allEvent(
      sort: { order: ASC, fields: [frontmatter___start_date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "event" }
          draft: { ne: true }
          cancelled: { ne: true }
        }
        fields: { future: { eq: true } }
      }
      limit: 5
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            price
            over24h
            primary
            cancelled
            start_year: start_date(formatString: "YYYY", locale: "fi")
            start_month: start_date(formatString: "MMM", locale: "fi")
            start_dow: start_date(formatString: "dd", locale: "fi")
            start_date(formatString: "l", locale: "fi")
            start_time: start_date(formatString: "LT", locale: "fi")
            end_date(formatString: "l", locale: "fi")
            end_time: end_date(formatString: "LT", locale: "fi")
          }
        }
      }
    }
    news: allNewsItem(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      edges {
        node {
          excerpt: excerptShort
          id
          frontmatter {
            rowId
            title
            date(formatString: "l", locale: "fi")
          }
        }
      }
    }
  }
`;
