import React from 'react';
import { Link } from 'gatsby';

export default class NewsList extends React.Component {
  render() {
    const { news, className } = this.props;

    return news.map(({ node: post }) => (
      <div
        className={className}
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
        key={post.id}
      >
        <p>
          <Link
            className="is-uppercase has-text-weight-bold"
            to={`/uutiset/${post.frontmatter.rowId}`}
          >
            {post.frontmatter.title}
          </Link>
          <span className="is-size-7 has-text-grey-light">
            {' '}
            &bull; {post.frontmatter.date}
          </span>
        </p>
        <p>{post.excerpt}</p>
      </div>
    ));
  }
}
